<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-col cols="6" class="flex-column item-bg" style="max-width: 49%">
        <ForbiddenAllowedList
          title="ALLOWED LIST"
          :subtitle="allowedHelpText"
          :list="allowedList"
          action="allow"
          @update_list="updateForbiddenAllowedList"
        />
      </b-col>
    </div>
  </div>
</template>

<script>
import { ToggleList, Switch } from "@/components";
import GeneralMixin from "../../../AsmConfigurations/general/GeneralMixin";
import ForbiddenAllowedList from "./ForbiddenAllowedList";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    [ToggleList.name]: ToggleList,
    [Switch.name]: Switch,
    ForbiddenAllowedList,
  },
  mixins: [GeneralMixin],
  props: {},
  data() {
    return {
      allowedHelpText: "Add URL or domain that you want always allow ",
    };
  },
  async created() {
  },
  methods: {
    ...mapMutations("ispServices/profiles", ["updateProfileServiceProp"]),
    updateForbiddenAllowedList(listType, list) {
      this.updateProfileServiceProp({
        serviceName: "ads_free",
        propName: "allowed_urls",
        value: list,
      });
    },
  },
  computed: {
    ...mapState("ispServices/profiles", ["profile"]),
    allowedList() {
      return this.profile.services_configuration.ads_free.allowed_urls;
    },
  },
};
</script>

<style scoped lang="scss">
  .item-bg {
    border-radius: 5px;
    background: #d7d7d73b;
    padding: 10px 10px;
    margin-bottom: 25px;
  }
</style>
