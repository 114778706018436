<template>
  <b-card class="px-1">
    <template v-slot:header>
      <h4 class="title mt-4 mb-0">
        <svgicon
          original
          class="icon"
          :icon="`${action}-list-icon`"
          width="44"
          height="44"
        />
        <span>{{ t(title) }}</span>
      </h4>
    </template>
    <b-row class="px-1">
      <b-col cols="12">
        <h4 class="my-2">{{ t(subtitle) }}</h4>
        <b-row class="align-items-baseline">
          <b-col cols="9">
            <fg-input
              v-model="url"
              :name="action"
              :placeholder="t('Enter a valid url *')"
              v-validate="{url: {require_protocol: false }, required: true}"
              :error="getError(action)"
            />
          </b-col>
          <b-col style="min-width: 120px" cols="3">
            <n-button
              type="primary"
              size="md"
              :disabled="!!getError(action)"
              round
              block
              @click.native="add_url(url)"
              :visible_for_permissions='[{actions: ["POST"], path: "/profiles"}]'
            >
              <span class="text-capitalize">{{ t(action) }}</span>
            </n-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="p-2 mt-4" style="border-radius: 5px">
        <paginated-table
          :showHeader="false"
          :itemsPerPage="10"
          :rows="rows"
          :columns="columns"
          :actions="actions"
          :search="['name']"
          @item-remove="remove_from_list"
          v-model="search_field_value"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { PaginatedTable } from "@/components";
import GeneralMixin from "@/components/AsmConfigurations/general/GeneralMixin";

export default {
  mixins: [GeneralMixin],
  components: {
    PaginatedTable,
  },
  props: {
    title: String,
    subtitle: String,
    action: String,
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      url: "",
      columns: [
        {
          prop: "index",
          minWidth: 30,
        },
        {
          prop: "name",
          minWidth: 100,
        },
      ],
      actions: {
        minWidth: 30,
        fixed: "right",
        items: [
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            event: "item-remove",
            action: this.t("Remove"),
            confirm: false,
            visible_for_permissions: [{actions: ["POST"], path: "/profiles"}]
          },
        ],
      },
      search_field_value: "",
    };
  },
  created() {
    this.search_field_value = "name";
  },
  methods: {
    add_url(url) {
      url = url.replace('http://', '').replace('https://','')
      if (url && !this.list.includes(url)) {
        let list = _.cloneDeep(this.list);
        list.unshift(url);
        this.$emit("update_list", this.action, list);
      }
      this.url = "";
    },
    remove_from_list(item) {
      item = item.row.name;
      let list = _.cloneDeep(this.list);
      list = list.filter((url) => url !== item);
      this.$emit("update_list", this.action, list);
    },
  },
  computed: {
    rows() {
      return this.list.map((url, idx) => {
        return Object.create(
          {},
          { name: { value: url }, index: { value: idx } }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  top: 0;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: transparent;
  cursor: not-allowed;
}
.title-list-icon {
  width: 48px;
  margin-right: 8px;
}
.card {
  background: #d7d7d700 !important;
}
::v-deep .card {
  background: #d7d7d700 !important;
}
</style>
