<template>
  <div>
    <b-col class="flex-column border-box">
      <h4 class="title"><i class="now-ui-icons files_box mr-2" />{{t('packages')}}</h4>
      <p class="mb-4">
        {{
          t('This section helps to manage AutoNotices packages enabled for the profile')
        }}
      </p>
      <div class="px-3 row">
        <div
          class="d-flex"
          style="margin-right: 60px; margin-bottom: 25px"
          v-for="autoNoticePackage in list"
          :key="autoNoticePackage.isp_notice_id"
        >
          <p class="mr-3 mb-0 text-capitalize">
            {{ t(translateHandler(autoNoticePackage.isp_notice_id, "_", "")) }}
          </p>
          <n-switch
            v-model="autoNoticePackage.enabled"
            class="mt-0 mb-0"
            @input="
              handlePackagesStatus($event, autoNoticePackage.isp_notice_id)
            "
            color="allot"
            :visible_for_permissions="visible_for_permissions"
          />
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import { ToggleList, Switch } from "@/components";
import GeneralMixin from "@/mixins/General";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    [ToggleList.name]: ToggleList,
    [Switch.name]: Switch,
  },
  mixins: [GeneralMixin],
  props: {
    visible_for_permissions: { 
      type: Array, 
      default: function () { return [] }
    }     
  },
  data() {
    return {
      list: [],
    };
  },
  async created() {
    await this.getIspAutoNoticePackages();
    this.$store.watch(
      (state) => state.ispServices.profiles.profile.services_configuration.auto_notice.notices,
      (val) => {
        this.list = _.cloneDeep(val);
      },
      {
        immediate: true
      },
    )
  },
  methods: {
    ...mapActions("ispServices/profiles", ["getIspAutoNoticePackages"]),
    ...mapMutations("ispServices/profiles", ["updateProfileServiceProp"]),
    handlePackagesStatus(value, pacakageName) {
      const list = _.cloneDeep(
        this.profile.services_configuration.auto_notice.notices
      );
      list.forEach((item) => {
        if (item.isp_notice_id === pacakageName) item.enabled = value;
      });
      this.updateProfileServiceProp({
        serviceName: "auto_notice",
        propName: "notices",
        value: list,
      });
    },
  },
  computed: {
    ...mapState("ispServices/profiles", ["profile"]),
  },
};
</script>

<style scoped lang="scss">
</style>
