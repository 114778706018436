<template>
  <div>
    <b-col class="flex-column border-box">
      <h4 class="title"><i class="now-ui-icons files_box mr-2" />{{t('Status')}}</h4>
      <p class="mb-4">
        {{ t('This section helps to manage threat protection status') }}
      </p>
      <div class="px-3 row">
        <div
          class="d-flex"
          style="margin-right: 60px; margin-bottom: 25px"
          v-for="(status, statusName) in statuses"
          :key="statusName"
        >
          <p class="mr-3 mb-0 text-capitalize">
            {{ t(translateHandler(statusName, "_enabled", "")) }}
          </p>
          <n-switch
            v-model="statuses[statusName]"
            class="mt-0 mb-0"
            @input="handleStatusUpdate($event, statusName)"
            color="allot"
            :visible_for_permissions="visible_for_permissions"
          />
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import { ToggleList, Switch } from "@/components";
import GeneralMixin from "@/mixins/General";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    [ToggleList.name]: ToggleList,
    [Switch.name]: Switch,
  },
  mixins: [GeneralMixin],
  props: {
    visible_for_permissions: { 
      type: Array, 
      default: function () { return [] }
    }    
  },
  data() {
    return {};
  },
  async created() {},
  methods: {
    ...mapMutations("ispServices/profiles", ["updateProfileServiceProp"]),
    handleStatusUpdate(value, propName) {
      this.updateProfileServiceProp({
        serviceName: "threat_protection",
        propName,
        value,
      });
    },
  },
  computed: {
    ...mapGetters("ispServices/profiles", ["threatProtectionStatuses"]),
    ...mapGetters("networksecure/status", ["networkSecureSnifferMode"]),
    ...mapGetters("settings", ["EShasAV"]),
    statuses() {
      return (this.networkSecureSnifferMode && !this.EShasAV)
        ? _.omit(this.threatProtectionStatuses, ["antivirus_enabled"])
        : this.threatProtectionStatuses;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
